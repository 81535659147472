<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="close"
  >
    <div style="width:100px; height: 30px; font-size: 16px">询盘</div>
    <el-table :data="list" size="small" style="width: 100%">
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="内部编号"
          prop="serial_number"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="SKU"
          prop="sku"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="当前状态"
          prop="state"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="客户"
          prop="customer"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="询价数"
          prop="query_number"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="预期数量"
          prop="expect_number"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="所需成色"
          prop="colour"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="备注"
          prop="note"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="询盘时间"
          prop="query_time"
          width="140"
      >
      </el-table-column>
    </el-table>
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <div style=" width:100px; line-height: 30px; margin-top: 10px; font-size: 16px">报价</div>
      <el-row>
        <el-col :span="12">
          <el-form-item label="供货商" prop="title">
            <el-input v-model="form.title" size="small" placeholder="请输入"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货期" prop="title">
            <el-input v-model="form.title" size="small" placeholder="请输入"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="成色">
            <el-select v-model="form.colour" size="small" style="width: 100%" multiple placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="数量" prop="title">
            <el-input type="number" v-model="form.title" size="small" placeholder="请输入"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="价格" prop="title">
            <el-input type="number" v-model="form.title" size="small" placeholder="请输入"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="title">
            <el-input v-model="form.title" autosize size="small" placeholder="请输入"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="">
        <el-button type="primary" size="small">提 交</el-button>
      </el-form-item>
    </el-form>
    <div style=" width:100px; line-height: 30px; margin-top: 10px; font-size: 16px">历史报价</div>
    <el-table :data="list" size="small" style="width: 100%" min-height="100" max-height="270">
      <el-table-column
          align="center"
          label="供货商"
          prop="serial_number"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="货期"
          prop="sku"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="成色"
          prop="state"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="数量"
          prop="customer"
          width="140"
      >
      </el-table-column>
      <el-table-column
          align="center"
          label="时间"
          prop="query_number"
          width="140"
      >
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">关 闭</el-button>
      <!--      <el-button type="primary" @click="save" size="small">确 定</el-button>-->
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'infoEdit',
  data() {
    return {
      list: [],
      form: {
        colour: '',
        title: '',
        content: '',
        state: '1',
      },
      rules: {
        title: [
          {required: true, trigger: 'blur', message: '请输入标题'},
        ],
      },
      title: '',
      dialogFormVisible: false,
      options: [
        {value: 0, label: '全新'},
        {value: 1, label: '九成新'},
        {value: 2, label: '八成新'},
        {value: 3, label: '七成新'},
        {value: 4, label: '六成新'},
        {value: 5, label: '五成新'},
      ],
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      // if (!row) {
      // this.title = '添加'
      // } else {
      // this.title = '修改'
      this.form = Object.assign({}, row)
      // }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const {code, message} = await doEdit(this.form)
          if (code == '200') {
            this.$message.success(message)
            this.$emit('fetch-data')
            this.close()
          } else {
            this.$message.error(message)
            this.$emit('fetch-data')
            this.close()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
