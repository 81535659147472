<template>
  <!--    <el-col :span="24" style="padding: 10px; box-sizing: border-box">
        <el-col :span="16">
          <el-button icon="el-icon-plus" type="primary" size="small">新增询盘</el-button>
          <span>
              自动刷新
              <el-tooltip
                  :content="form.is_issue == 1 ? '打开' : '关闭'"
                  :enterable="false"
                  placement="top"
              >
                <el-switch
                    v-model="form.is_issue"
                    active-color="#13ce66"
                    :active-value="1"
                    inactive-color="#ff4949"
                    style="margin-left: 10px"
                    :inactive-value="0"
                />
              </el-tooltip>
            </span>
          <el-divider direction="vertical"></el-divider>
          <span>
              隐藏已有报价
              <el-tooltip
                  :content="form.is_issue == 1 ? '打开' : '关闭'"
                  :enterable="false"
                  placement="top"
              >
                <el-switch
                    v-model="form.is_issue"
                    active-color="#13ce66"
                    :active-value="1"
                    inactive-color="#ff4949"
                    style="margin-left: 10px"
                    :inactive-value="0"
                />
              </el-tooltip>
            </span>
          <el-divider direction="vertical"></el-divider>
          <span>
              最新在前
              <el-tooltip
                  :content="form.is_issue == 1 ? '打开' : '关闭'"
                  :enterable="false"
                  placement="top"
              >
                <el-switch
                    v-model="form.is_issue"
                    active-color="#13ce66"
                    :active-value="1"
                    inactive-color="#ff4949"
                    style="margin-left: 10px"
                    :inactive-value="0"
                />
              </el-tooltip>
            </span>
        </el-col>
        <el-col :span="8" style="text-align: right">
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item>
              <el-input v-model="queryForm.keyword" type="text" clearable size="small" placeholder="请输入关键字"></el-input>
            </el-form-item>
            &lt;!&ndash;          <el-form-item>&ndash;&gt;
            <el-button icon="el-icon-search" type="primary" size="small">
              查询
            </el-button>
          </el-form>

        </el-col>
        &lt;!&ndash;      <hr/>&ndash;&gt;
      </el-col>-->
  <el-row>
    <el-col :span="24" style="padding: 10px; box-sizing: border-box">
      <el-col :span="16">
        <el-button icon="el-icon-plus" type="primary" size="small" @click="handleEdit">新增询盘</el-button>
        <span>
            自动刷新
            <el-tooltip
                :content="form.is_issue == 1 ? '打开' : '关闭'"
                :enterable="false"
                placement="top"
            >
              <el-switch
                  v-model="form.is_issue"
                  active-color="#13ce66"
                  :active-value="1"
                  inactive-color="#ff4949"
                  style="margin-left: 10px"
                  :inactive-value="0"
              />
            </el-tooltip>
          </span>
        <el-divider direction="vertical"></el-divider>
        <span>
            隐藏已有报价
            <el-tooltip
                :content="form.is_issue == 1 ? '打开' : '关闭'"
                :enterable="false"
                placement="top"
            >
              <el-switch
                  v-model="form.is_issue"
                  active-color="#13ce66"
                  :active-value="1"
                  inactive-color="#ff4949"
                  style="margin-left: 10px"
                  :inactive-value="0"
              />
            </el-tooltip>
          </span>
        <el-divider direction="vertical"></el-divider>
        <span>
            最新在前
            <el-tooltip
                :content="form.is_issue == 1 ? '打开' : '关闭'"
                :enterable="false"
                placement="top"
            >
              <el-switch
                  v-model="form.is_issue"
                  active-color="#13ce66"
                  :active-value="1"
                  inactive-color="#ff4949"
                  style="margin-left: 10px"
                  :inactive-value="0"
              />
            </el-tooltip>
          </span>
      </el-col>
      <el-col :span="8" style="text-align: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input v-model="queryForm.keyword" type="text" clearable size="small" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <!--          <el-form-item>-->
          <el-button icon="el-icon-search" type="primary" size="small">
            查询
          </el-button>
        </el-form>

      </el-col>
      <!--      <hr/>-->
    </el-col>
    <div v-for="(item, index) in tempArr" :key="index">
      <el-col :span="4">
        <el-card :body-style="{ padding: '0px', height: '200px' }"
                 :style="{margin: '5px', backgroundColor: item.state == 1 ? '#ffebcc' : '#ffffff'}"
        >
          <div
              :style="{padding: '14px', boxSizing: 'border-box'}" @click="queryInfo(item.id)">
            <div style="line-height:30px; border-bottom: 2px solid #DCDFE6">型号：{{ item.name }}</div>
            <div class="bottom clearfix" style="font-size: 12px; line-height: 20px; border-bottom: 2px solid #DCDFE6">
              <div>新旧程度：</div>
              <div>数量：</div>
              <div>价格：</div>
              <div class="note_descr">备注：{{ item.descr }}</div>
            </div>
            <!--            <hr/>-->
            <div
                style="font-size: 12px">
              <div>公司：</div>
              <div>报价：</div>
              <div class="text_descr">备注：{{ item.descr }}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </div>
    <Edit ref="Edit" @fetch-data="fetchData"/>
    <info-edit ref="infoEdit" @fetch-data="fetchData"/>
  </el-row>
</template>

<script>
import {apiGetKeywordList} from "@/request/api";
import Edit from './components/Edit'
import infoEdit from './components/infoEdit'

export default {
  name: "pirceCard",
  components: {Edit, infoEdit},
  data() {
    return {
      currentDate: new Date(),
      drawer: true,
      form: {
        is_issue: 1,
      },
      queryForm: {
        keyword: '',
        code: '',
      },
      list: [],
      suspendiv: false,
      tempArr: [
        {id: 1, name: '型号1', state: 1, descr: '士大夫是浦东收到附件是外婆佛i撒旦埃斯珀飞机撒旦飞洒皮开发的美女山坡地覅九二，苏东坡覅什么的评审是开的发票就撒豆IP发'},
        {id: 2, name: '型号2', state: 0, descr: ''},
        {id: 3, name: '型号3', state: 0, descr: ''},
        {id: 4, name: '型号4', state: 1, descr: ''},
        {id: 5, name: '型号5', state: 0, descr: ''},
        {id: 6, name: '型号6', state: 0, descr: ''},
        {id: 7, name: '型号7', state: 0, descr: ''},
        {id: 8, name: '型号8', state: 1, descr: ''},
        {id: 9, name: '型号9', state: 0, descr: ''},
      ],
    }
  },
  created() {
    // this.fetchData()
  },
  methods: {
    queryInfo(id) {
      if (id) {
        this.$refs['infoEdit'].showEdit(id)
      }
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    enter() {
      this.suspendiv = true
    },
    leave() {
      this.suspendiv = false
    },
    mouseover() {
      this.suspendiv = true
    },
    async fetchData() {
      this.listLoading = true
      apiGetKeywordList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}
</script>

<style scoped>
.note_descr {
  display: -webkit-box; /*弹性伸缩盒子*/
  -webkit-box-orient: vertical; /*子元素垂直排列*/
  -webkit-line-clamp: 1; /*显示的行数*/
  overflow: hidden; /*溢出部分隐藏*/
  font-size: 10px
}

.text_descr {
  display: -webkit-box; /*弹性伸缩盒子*/
  -webkit-box-orient: vertical; /*子元素垂直排列*/
  -webkit-line-clamp: 2; /*显示的行数*/
  overflow: hidden; /*溢出部分隐藏*/
  font-size: 10px
}
</style>