<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="客户" prop="user_id">
            <el-select
                v-model="form.user_id"
                multiple
                filterable
                remote
                size="small"
                style="width: 100%"
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="询盘单" prop="patrol">
            <el-select v-model="form.patrol" style="width: 100%" clearable size="small" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户单" prop="patrol">
            <el-select v-model="form.patrol" style="width: 100%" clearable size="small" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报价单" prop="patrol">
            <el-select v-model="form.patrol" style="width: 100%" clearable size="small" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="型号">
        <el-table :data="s_list" size="small" style="width: 100%">
          <el-table-column
              align="center"
              label="型号"
              prop="model"
              width="140"
          >
            <template slot-scope="scope">
              <el-input v-if="scope.row.model" v-model="s_list[scope.$index].model" placeholder="请输入型号"
                        :value="scope.row.model" size="small"/>
              <el-input v-else v-model="s_list[scope.$index].model" placeholder="请输入型号" :value="0" size="small"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="数量"
              prop="number"
              width="140"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.number" v-model="s_list[scope.$index].number" placeholder="请输入数量"
                        :value="scope.row.number" size="small"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].number" placeholder="请输入数量" :value="0"
                        size="small"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="单价"
              prop="price"
              width="140"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price" placeholder="请输入单价"
                        :value="scope.row.price" size="small"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入单价" :value="0"
                        size="small"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="类型"
              prop="type"
              width="140"
          >
            <template slot-scope="scope">
              <el-input v-if="scope.row.type" v-model="s_list[scope.$index].type" placeholder="请输入类型"
                        :value="scope.row.type" size="small"/>
              <el-input v-else v-model="s_list[scope.$index].type" placeholder="请输入类型" :value="0" size="small"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="备注"
              prop="note"
              width="200"
          >
            <template slot-scope="scope">
              <el-input v-if="scope.row.note" type="textarea" autosize v-model="s_list[scope.$index].note"
                        placeholder="请输入备注" :value="scope.row.note" size="small"/>
              <el-input v-else type="textarea" autosize v-model="s_list[scope.$index].note" placeholder="请输入备注"
                        :value="0" size="small"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="80">
            <template slot-scope="scope">
              <el-button type="text" @click="s_list.splice(scope.$index,1)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" size="small" @click="addMessage">增 加</el-button>
      </el-form-item>
      <el-form-item label="重要程度" prop="radio1">
        <el-radio-group v-model="form.radio1" size="small">
          <el-radio-button v-for="(item,index) in radiosArr" :key="index" :label="item.id">{{
              item.name
            }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-col :span="12">
        <el-form-item label="备注" prop="content">
          <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="form.content" placeholder="请输入备注"
                    size="small"/>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <span v-for="(item, index) in labels" :key="index">
          <el-button plain size="small" style="margin: 3px">{{ item }}</el-button>
        </span>
      </el-col>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" @click="save" style="margin-top: 10px; width: 30%; height: 50px; border-radius: 5px">保
        存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'Edit',
  data() {
    return {
      loading: false,
      labels: ['严苛用户', '必须全新', '薄利多销', '质量好', '严苛用户', '必须全新', '薄利多销', '质量为先'],
      radiosArr: [
        {id: 0, name: '一般'},
        {id: 1, name: '重要'},
        {id: 2, name: '紧急'},
        {id: 3, name: '十万火急'},
      ],
      s_list: [
        {
          model: 'TW123',
          number: 12,
          price: 26,
          type: '芯片',
          note: '电子元器件',
        },
      ],
      options: [],
      form: {
        user_id: '',
        patrol: '',
        radio1: 1,
        title: '',
        content: '',
        state: '1',
      },
      list: [],
      rules: {
        title: [
          {required: true, trigger: 'blur', message: '请输入标题'},
        ],
      },
      title: '',
      dialogFormVisible: false,
      states: ["Alabama", "Alaska", "Arizona",
        "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida",
        "Georgia", "Hawaii", "Idaho", "Illinois",
        "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota",
        "Mississippi", "Missouri", "Montana",
        "Nebraska", "Nevada", "New Hampshire",
        "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio",
        "Oklahoma", "Oregon", "Pennsylvania",
        "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas",
        "Utah", "Vermont", "Virginia",
        "Washington", "West Virginia", "Wisconsin",
        "Wyoming"],
    }
  },
  created() {
  },
  mounted() {
    this.list = this.states.map(item => {
      return {id: `value:${item}`, label: `label:${item}`};
    });
  },
  methods: {
    addMessage() {
      this.s_list.push({
        model: '',
        number: '',
        price: '',
        type: '',
        note: '',
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          // const {code, message} = await doEdit(this.form)
          // if (code == '200') {
          //   this.$message.success(message)
          //   this.$emit('fetch-data')
          //   this.close()
          // } else {
          //   this.$message.error(message)
          //   this.$emit('fetch-data')
          //   this.close()
          // }
        } else {
          return false
        }
      })
    },
  },
}
</script>
